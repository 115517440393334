import React, { Component } from 'react';
import './App.css';

class Browse extends Component {
  render() {
    return(
      <React.Fragment>
        <div className="section nomargin">
          <div className="container">

            <div className="heading-block nobottomborder mb-5 center">
              <h3><a name="browse">Recently Triggered</a></h3>
              <span>See something you like? Subscribe to buy when it happens.</span>
            </div>

            <div className="clear"></div>

            <div className="row mt-2">

              <div className="col-md-4 mb-5">
                <div className="card course-card hover-effect noborder">
                  <div className="card-head">
                    <blockquote class="twitter-tweet" data-lang="en"><p lang="en" dir="ltr">This is the great fallacy underlying Bitcoin. Money&#39;s primary function is exchange, not store of value. Things that are primarily excellent stores of value are not money. Indeed if they become money, their usefulness as store of value declines. <a href="https://t.co/s9xodnIkhL">https://t.co/s9xodnIkhL</a></p>&mdash; (((Frances Coppola))) (@Frances_Coppola) <a href="https://twitter.com/Frances_Coppola/status/1131560205798518785?ref_src=twsrc%5Etfw">May 23, 2019</a></blockquote>
                    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                  </div>
                  <div className="card-body">
                    <p>We buy up to 0.5BTC whenever <b>@Frances_Coppola</b> mentions <b>bitcoin</b></p>
                  </div>

                  <div className="card-footer py-3 d-flex justify-content-between align-items-center bg-white text-muted">
                    <div className="badge alert-primary">Follow</div>
                    <a href="#" className="text-dark position-relative"><i className="icon-line2-user"></i> <span className="author-number">5</span></a>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-5">
                <div className="card course-card hover-effect noborder">
                  <div className="card-head">
                    <blockquote class="twitter-tweet" data-lang="en"><p lang="en" dir="ltr">oh yeah. bitcoiners, broadly speaking, know fuck all about anything they talk about, and are guided only by their sense of what&#39;s Good News for Bitcoin and sounds like it will make number go up. No other detail matters.</p>&mdash; David Gerard (@davidgerard) <a href="https://twitter.com/davidgerard/status/1140501216625082368?ref_src=twsrc%5Etfw">June 17, 2019</a></blockquote>
                    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

                  </div>
                  <div className="card-body">
                    <p>We buy up to 0.7BTC whenever <b>@davidgerard</b> says <b>number go up</b></p>
                  </div>

                  <div className="card-footer py-3 d-flex justify-content-between align-items-center bg-white text-muted">
                    <div className="badge alert-primary">Follow</div>
                    <a href="#" className="text-dark position-relative"><i className="icon-line2-user"></i> <span className="author-number">5</span></a>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-5">
                <div className="card course-card hover-effect noborder">
                  <div className="card-head">
                    <blockquote class="twitter-tweet" data-lang="en"><p lang="en" dir="ltr">oh yeah. bitcoiners, broadly speaking, know fuck all about anything they talk about, and are guided only by their sense of what&#39;s Good News for Bitcoin and sounds like it will make number go up. No other detail matters.</p>&mdash; David Gerard (@davidgerard) <a href="https://twitter.com/davidgerard/status/1140501216625082368?ref_src=twsrc%5Etfw">June 17, 2019</a></blockquote>
                    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

                  </div>
                  <div className="card-body">
                    <p>We buy up to 0.7BTC whenever <b>@davidgerard</b> says <b>number go up</b></p>
                  </div>

                  <div className="card-footer py-3 d-flex justify-content-between align-items-center bg-white text-muted">
                    <div className="badge alert-primary">Follow</div>
                    <a href="#" className="text-dark position-relative"><i className="icon-line2-user"></i> <span className="author-number">5</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="section nobg padded nomargin">
          <div className="container">

            <div className="heading-block nobottomborder mb-5 center">
              <h3>Most Followed</h3>
              <span>Kattara users follow these Twitter personalities to trigger Bitcoin buys.</span>
            </div>

            <div className="clear"></div>

            <div className="row">

              <div className="col-lg-3 col-sm-6 mb-4">
                <div className="feature-box hover-effect shadow-sm fbox-center fbox-bg fbox-light fbox-effect">
                  <div className="fbox-icon">
                    <i><img src="https://randomuser.me/api/portraits/women/21.jpg" className="noborder nobg shadow-sm zindex2" alt=""/></i>
                  </div>
                  <p className="text-dark"><strong>0.7 BTC</strong> Purchased</p>
                  <p className="text-dark mt-0"><strong>23</strong> Buyers</p>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 mb-4">
                <div className="feature-box hover-effect shadow-sm fbox-center fbox-bg fbox-light fbox-effect">
                  <div className="fbox-icon">
                    <i><img src="https://randomuser.me/api/portraits/women/57.jpg" className="noborder nobg shadow-sm zindex2" alt=""/></i>
                  </div>
                  <p className="text-dark"><strong>0.3 BTC</strong> Purchased</p>
                  <p className="text-dark mt-0"><strong>21</strong> Buyers</p>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 mb-4">
                <div className="feature-box hover-effect shadow-sm fbox-center fbox-bg fbox-light fbox-effect">
                  <div className="fbox-icon">
                    <i><img src="https://randomuser.me/api/portraits/men/20.jpg" className="noborder nobg shadow-sm zindex2" alt=""/></i>
                  </div>
                  <p className="text-dark"><strong>2.1 BTC</strong> Purchased</p>
                  <p className="text-dark mt-0"><strong>11</strong> Buyers</p>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 mb-4">
                <div className="feature-box hover-effect shadow-sm fbox-center fbox-bg fbox-light fbox-effect">
                  <div className="fbox-icon">
                    <i><img src="https://randomuser.me/api/portraits/women/85.jpg" className="noborder nobg shadow-sm zindex2" alt=""/></i>
                  </div>
                  {/* <h3 className="mb-4 nott ls0"><a href="#" className="text-dark">Destiny Eagle</a><br/><small className="subtitle nott color">Lifestyle</small></h3> */}
                  <p className="text-dark"><strong>3.2 BTC</strong> Purchased</p>
                  <p className="text-dark mt-0"><strong>12</strong> Buyers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Browse;
