
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import './App.css';
import Home from './Home';

import dotenv from "dotenv";
dotenv.config({ });

class App extends Component {
  render() {
    return (
    <div id="wrapper" className="clearfix">
      <Router>
        <Route exact path="/"
          component={Home}
        />
      </Router>
    </div>
    );
  }
}

export default App;
