import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Cookies from 'universal-cookie';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import './App.css';
import uuidv1 from 'uuid/v1';

let oauthState;

console.log("process env node", process.env.NODE_ENV);
console.log("process backend url", process.env.REACT_APP_BACKEND_URL);
const cookies = new Cookies();
const BACKEND = process.env.REACT_APP_BACKEND_URL;
const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;
const userName = cookies.get('coinbase.name');

class TopBar extends Component {
  coinbaseLogin() {
    // FIXME: when we redirect back to the front end, save oauthState
    // in a cookie and validate that it hasn't changed on callback
    oauthState = uuidv1();
    window.location = `${BACKEND}/oauth/coinbase/request_token?state=${oauthState}`;
  }

  logout() {
    cookies.remove("coinbase.name", {domain: BACKEND_DOMAIN});
    window.location = `${BACKEND}/logout`;
  };

  accountDropdown() {
    if (userName) {
        return(
          <li>
            <NavDropdown title={userName + "'s Account"} id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.2">Followed Words</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.1">Purchases</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Settings</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                <Link onClick={this.logout}>Log Out</Link>
              </NavDropdown.Item>
            </NavDropdown>
          </li>
        )
    } else {
      return(
        <li className="nav-item">
          <Link className="nav-link button button-rounded button-border button-small" onClick={this.coinbaseLogin}>
            Login
          </Link>
        </li>
      )
    }
  }

  render() {
    return(
    <React.Fragment>

      <Navbar sticky="top" bg="white" expand="lg">
        <Navbar.Brand href="#home">
            <div id="logo">
              <a href="/" className="standard-logo"><img src="img/kattara.png" alt="Canvas Logo" /></a>
              <a href="/" className="retina-logo"><img src="img/kattara.png" alt="Canvas Logo" /></a>
            </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
              <ul className="navbar-nav ml-auto">
                {this.accountDropdown()}
              </ul>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
    )
  }
}

export default TopBar;
