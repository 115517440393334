import React, { Component } from 'react';
import TopBar from './TopBar';
import Intro from './Intro';
import Browse from './Browse';

class Home extends Component {
  render() {
    return (
      <div>
        <TopBar></TopBar>
        <Intro></Intro>
        <Browse></Browse>
      </div>
    )
  }
};

export default Home;
