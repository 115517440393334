import React, { Component } from 'react';
import './App.css';

class Intro extends Component {
  render() {
    return(
      <div className="section m-0 intro nomargin" >
        <div className="container">
          <div className="row">

            <div className="col-md-7"></div>

            <div className="col-md-5 light">
              <div className="heading-block nobottomborder mb-4 mt-5">
                <h3>Buy Bitcoin When They Tweet</h3>
                <p>Connect your exchange account. We don't touch your coins.</p>
                <p className="mb-2">Subscribe to your favorite trigger phrases like "blockchain" to buy Bitcoin when people tweet. We'll tweet at them for you, and let them know about their contribution to the cause.</p>
              </div>
              <a href="#" className="button button-rounded button-xlarge ls0 nott t400 nomargin">Let's Do This</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Intro;
